@import '../../styles/variable';

.box_shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.502);
}

.app__bar {
  background-color: $theme-black;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 0 16px;
  box-sizing: border-box;
  .app__bar-left-heading {
    display: flex;
    cursor: pointer;

    .title {
      display: flex;
      align-items: center;
      .text {
        .label {
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          color: white;
          // padding-bottom: 4px;
          padding-top: 4px;
        }
        .description {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
        }
      }
      .logo {
        // height: 32px;
        // width: 32px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
      .text {
        .label {
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          color: white;
          // padding-bottom: 4px;
          padding-top: 4px;

        }
        .description {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
        }
      }
    }
    .left-icon {
      padding: 0px 10px;
      margin: auto;
      width: 18px;
      height: 19px;
    }
    p {
      font-family: $font-family-bold;
      margin-left: 12px;
      font-weight: 500;
      font-size: 18px;
      color: $white;
      line-height: 21px;
    }
    small {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }

    .app-bar-title {
      display: flex;
      align-items: center;
    }
  }
  .app__bar-right-heading {
    .app__bar-cancel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      small {
        font-weight: 500;
        font-size: 9px;
        color: $white;
      }
      img {
        width: 32px;
        height: 32px;
      }
    }

    p {
      font-family: $font-family-bold;
      font-weight: 500;
      font-size: 14px;
      color: $white;
      text-align: right;
    }
  }
}

@media (max-width: 750px) {
  .app__bar {
    width: 100%;

    .app__bar-heading {
      margin: 4%;
    }
  }
}
