@import '../../styles/index.scss';

.toast_wrapper {
  background-color: #000000e5;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 12px;
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  left: 16px;
  right: 16px;
  border-radius: 8px;
  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 14px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    font-weight: 500;
    font-size: 12px;

    color: #ffffff;
  }
}
