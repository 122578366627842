@import '../../styles/index.scss';

.image-box {
  width: calc(100% / 1 - 12px);
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  outline-style: none;
  img {
    width: 100%;
    height: 100%;
  }
}
