@import '../../styles/index.scss';

.payment-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 23%;
  margin: 0 1%;
  .check-icon {
    position: absolute;
    right: 13%;
    height: 20px !important;
    width: 20px !important;
  }
  .icon {
    padding: 2px 8px;
    position: relative;
    img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }
  .tag-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    .tag {
      color: $white;
      font-size: 16px;
      text-align: center;
    }
    .subtag {
      color: $light-grey-2;
      font-size: 12px;
      text-align: center;
      font-family: $font-family-medium;
      padding: 2px;
      width: 70%;
    }
  }
}

@media only screen and (max-width: 420px) {
  .payment-btn {
    .icon {
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
    .check-icon {
      position: absolute;
      right: 13%;
      height: 16px !important;
      width: 16px !important;
    }
    .tag-wrapper {
      .tag {
        font-size: $font-medium;
      }
      .subtag {
        font-size: $font-extra-small;
      }
    }
  }
}
