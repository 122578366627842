@import '../../styles/variable';

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(25, 25, 25, 0.8);
  width: 100%;
  height: 100%;
  &.deactive {
    display: none;
  }
  .modal__wrapper-inner {
    background-color: $outline-white;
    border-radius: 8px;
    overflow: hidden;
    .modal-header {
      display: flex;
      font-weight: 500;
      font-size: 16px;
      align-items: center;
      color: #9e9e9e;
      padding: 16px;
      text-align: center;
      position: relative;
      justify-content: center;
      .crossbutton {
        right: 22px;
        position: absolute;
      }
    }

    .modal-content {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
