.payment-hold-screen {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 64px;
  right: 0;
  left: 0;
  padding: 0 66px;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  &__heading {
    color: #eeeeee;
    font-size: 14px;
    margin-bottom: 48px;
    margin-top: 88px;
  }
  &__timer {
    margin-bottom: 32px;
  }
  &__info {
    color: #666666;
    font-size: 12px;
  }
  &__cancel {
    color: #9e9e9e;
    font-size: 14px;
    margin-top: 32px;
  }
  &__sub-heading {
    color: #eeeeee;
    font-size: 14px;
    margin-top: -40px;
    margin-bottom: 48px;
  }
}
