@import '../../../../../styles/variable';

.palns-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  color: #424242;
  box-sizing: border-box;
  padding: 10px;
  .offer-benefits {
    text-align: center;
    margin: 20px;
    img {
      width: 90%;
    }
  }
}

.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.plan {
  display: flex;
  border-radius: 8px;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  flex-basis: 100%;

  .price-container {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .price-inner-container {
      display: flex;
      align-items: baseline;
      img {
        width: 20px;
        height: 20px;
      }

      .origional-price {
        text-decoration: line-through;
        margin: 0 20px;
      }
      .discount-price {
        font-weight: bold;
        .small {
          font-size: 12px;
        }
        .large {
          font-size: 24px;
        }
      }
    }
    .period {
      border: 1px solid #c7c7c7;
      border-radius: 10px;
      font-size: 12px;
      padding: 0.24rem 0.6rem;
      background: #ffffff;
    }
  }
}

.register-btn {
  cursor: pointer;
  background-color: #01b9f5;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  border: none;
  outline: none;
  width: 100%;
  padding: 12px 0;
  border-radius: 4px;
}

.select-plan-bg {
  background: #eeeeee;
}

.non-select-plan-bg {
  background: #f7f7f7;
}

.selected-radio-bg {
  background: #01b9f5;
}

.non-selected-radio-bg {
  background: #d6d6d6;
}

.auto-renew-txt {
  font-size: 12px;
  color: #767676;
  margin-top: 5px;
}
