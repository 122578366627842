@import '../../../../../../../styles/variable';

.palns__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: $white;
  color: #424242;
  box-sizing: border-box;
  padding: 10px;

  .offer-benefits {
    text-align: center;
    margin: 20px;
    img {
      width: 90%;
    }
  }
}

.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.register-btn {
  cursor: pointer;
  background-color: #01b9f5;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  border: none;
  outline: none;
  width: 100%;
  padding: 12px 0;
  border-radius: 4px;
}

.auto-renew-txt {
  font-size: 12px;
  color: #767676;
  margin-top: 5px;
}
