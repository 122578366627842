@import '../../styles/index.scss';

.screen-center-text {
  flex-direction: column;
  .success-sign {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: solid 1.5px #16b27e;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px;
  }
  .success-content {
    margin: 30px 0px;
    .header {
      font-family: $font-family;
      font-size: 20px;
      text-align: center;
      margin: 30px 0 8px;
      color: #eeeeee;
    }
    .title {
      font-size: 14px;
      margin: 8px 10px 20px;
      text-align: center;
    }
    .subtitle {
      font-size: 14px;
      color: #9e9e9e;
      margin: 20px 10px;
      text-align: center;
    }
  }
  .button-wrapper {
    width: 80%;
    position: fixed;
    bottom: 45px;
  }
}
