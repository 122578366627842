@import '../../styles/index';

@mixin li-text($font-family, $font-size, $color) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: 100%;
  color: $color;
  display: flex;
  align-items: center;
  list-style-type: none;
}

@mixin dropdown-wrapper($height) {
  width: 128px;
  height: $height;
  background: #313131;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  position: relative;
  justify-content: center;
}

.dropdown__wrapper-close {
  @include dropdown-wrapper(44px);
  .dropdown__wrapper-header {
    display: flex;
    justify-content: space-around;

    p {
      @include li-text($font-family-regular, $font-small, $outline-white);
    }
  }
  .dropdown__wrapper-body {
    display: none;
  }
}

.dropdown__wrapper-open {
  @include dropdown-wrapper(44px);

  .dropdown__wrapper-header {
    position: relative;
    #icon {
      position: absolute;
      right: 10px;
      top: 9px;
      z-index: 11;
    }
    p {
      list-style-type: none;
      display: none;
    }
  }
  .dropdown__wrapper-body {
    height: 172px;
    background: #313131;
    z-index: 1;
    border-radius: 4px;
    li {
      padding: 10px;
      @include li-text($font-family-regular, $font-small, $outline-white);
    }
  }
}

@media only screen and (max-width: 420px) {
  .dropdown__wrapper-close {
    @include dropdown-wrapper(32px);
  }

  .dropdown__wrapper-open {
    @include dropdown-wrapper(32px);
  }
}
