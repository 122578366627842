@import '../../styles/index.scss';

.overlay_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #191919cc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5555;

  .connection_wrapper {
    width: 400px;
    height: 200px;
    border-radius: 4px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 3.2%;
    @media only screen and (max-width: 420px) {
      width: 285px;
      height: 140px;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 5px;
      border: none;
      background: transparent;
      outline: none;
      &:hover {
        cursor: pointer;
      }
    }
    .heading {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #191919;
      margin-top: 48px;
      @media only screen and (max-width: 420px) {
        font-size: 16px;
        margin-top: 25px;
      }
    }
    .sub-heading {
      margin-top: 16px;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: #191919;
      @media only screen and (max-width: 420px) {
        font-size: 14px;
        margin-top: 8px;
      }
    }
    .refresh {
      border: none;
      outline: none;
      background: #ffd566;
      border-radius: 4px;
      width: 105px;
      height: 40px;
      margin-top: 25px;
      color: #000000;
      @media only screen and (max-width: 420px) {
        margin-top: 22px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
