@font-face {
  font-family: 'RobotoBlack';
  src: url('../assets/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'RobotoLight';
  src: url('../assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'RobotoMedium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

$font-family: 'Roboto';
$font-family-regular: 'Roboto';
$font-family-medium: 'Roboto';
$font-family-light: 'Roboto';
$font-family-bold: 'Roboto';
$font-family-black: 'Roboto';

$dark-theme-color: #282c34;
$app-bar-theme: #4d4d4d;

$theme-black: #212121;
$theme-red: #d8272c;
$grey: #333333;
$red-1: #e10000;

$grey-0: #333333;
$light-grey-1: #666666;
$light-grey-2: #9e9e9e;
$outline-white: #eeeeee;
$white: #ffffff;
$textbox-color: #313131;
$plan-border: #cccc;
$plan-theme-color: #852492;
$black-1: #191919;

$font-medium: 14px;
$font-large: 16px;
$font-small: 12px;
$font-medium-large: 32px;
$font-extra-large: 40px;
$font-extra-small: 9px;
$font-very-small: 10px;
$font-more-large: 20px;

$best-value-theme: #b1a568;
$card-type: #292929;

$music-theme-red: #eb1d22;
$music-grey: #e3e3e3;
$music-light-grey: #9da0a3;
$music-dark-grey: #767676;
$music-extra-dark-grey: #4e5052;
$music-very-dark-grey: #282f36;
$music-blue: #0288d1;
$music-off-white: #f9f9f9;

$page-bg: #0c0f12;
$card-bg: #151a1f;
$img-bg: #1f272b;
$small-font-clr: #bec1c2;
$bright-white: #dedede;

/* position variables */
$pos-abs: absolute;
$pos-fix: fixed;
$pos-rel: relative;

/* rajtv variables */
$rajtv-theme-color: #FFD566;
$rajtv-theme-color-black: #191919;
$rajtv-plan-card-btn-active: $rajtv-theme-color;

$enterr10-theme-color: #FFD01E;
$enterr10-theme-color-black:  #191919;
$enterr10-plan-card-btn-active: $enterr10-theme-color;
