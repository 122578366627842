@import '../../styles/index.scss';

.input__wrapper {
  .upi {
    background-color: $textbox-color;
  }

  input {
    outline: none;
    // background: #004fa1;
    border: none;
  }
}
