@import '../../../../../../../styles/variable';

.plan_container {
  display: flex;
  border-radius: 8px;
  height: 100px;
  margin: 5px;
  box-sizing: border-box;
  align-items: center;
  flex-basis: 45%;

  .price-container {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .price-inner-container {
      display: flex;
      align-items: baseline;
      img {
        width: 20px;
        height: 20px;
        align-self: center;
      }

      .origional-price {
        text-decoration: line-through;
        margin: 0 8px;
      }
      .discount-price {
        font-weight: bold;
        .small {
          font-size: 12px;
        }
        .large {
          font-size: 20px;
        }
      }
    }
    .period {
      border: 1px solid #c7c7c7;
      border-radius: 10px;
      font-size: 12px;
      padding: 0.15rem 0.6rem;
      background: #ffffff;
    }
  }
}

.select-plan-bg {
  background: #eeeeee;
}

.non-select-plan-bg {
  background: #f7f7f7;
}

.selected-radio-bg {
  background: #01b9f5;
}

.non-selected-radio-bg {
  background: #d6d6d6;
}
