@import '../../styles/variable';

.bottom_modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(25, 25, 25, 0.8);
  width: 100%;
  height: 100%;
  &__deactive {
    display: none;
  }
  &__inner {
    background-color: $outline-white;
    overflow: hidden;
    &__content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
