@import '../../styles/variable';

.chevron {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px 6px;
}

.chevron90 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 6px;
  transform: rotate(90deg);
}

.chevron-90 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 6px;
  transform: rotate(-90deg);
}

.chevron-180 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 6px;
  transform: rotate(-180deg);
}
.chevron180 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0px 6px;
  transform: rotate(180deg);
}

.check-mark {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.partner-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  box-sizing: border-box;

  .img-container {
    background: #9e9e9e;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .title {
    font-size: $font-extra-small;
    color: $white;
    width: 63px;
    text-overflow: ellipsis;
    margin-top: 8px;
    overflow: hidden;
    text-align: center;
  }
}

.rupee {
  svg {
    padding: 0px 3px 2px 0px;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  span {
    color: $white;
    margin-top: 8px;
    font-family: $font-family-regular;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    width: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  img {
    width: 72px;
    height: 72px;
  }
}

@media only screen and (max-width: 480px) {
  .icon {
    span {
      font-size: 14px;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
}

.checked {
  filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(246deg) brightness(105%) contrast(104%);
  height: 15px;
}
