@import '../../styles/index.scss';

.wrapper {
  .list-number {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-regular;
    font-weight: 500;
    font-size: $font-large;
    color: $theme-black;
    &:hover {
      background: rgba(158, 158, 158, 0.25);
    }
  }
}
.netbanking-wrapper {
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-left: 16px;
  &__image {
    height: 36px;
    width: 36px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
  .netbanking {
    font-family: $font-family-regular;
    font-weight: 500;
    font-size: $font-small;
    color: $theme-black;
    margin-left: 8px;
  }
}

.upidropdown-wrapper {
  .upidropdown {
    font-family: $font-family-regular;
    font-size: $font-small;
    color: $outline-white;
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 10px 0px 28px 8px;
    width: 114px;
  }
}
