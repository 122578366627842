@import '../../../../styles/index.scss';

$grey: #eeeeee;

.value_pack_wrapper {
  &__header {
    position: relative;
    .bottom_gradient {
      background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, #2b2b2b 98.35%);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 93px;
    }
    .slick-track {
      height: calc(100vh - 414px);
      // @media only screen and (max-width: 768px) and (min-width: 430px) {
      //   height: calc(100vh - 59vh);
      // }
    }
    .slick-dots {
      z-index: 4;
      bottom: 16px;
      display: flex !important;
      justify-content: flex-start;
      li {
        width: 4px;
      }
      li button::before {
        color: #eeeeee;
      }
      .slick-active button::before {
        color: #ffffff;
        opacity: 1;
      }
    }
    .image-box {
      height: calc(100vh - 414px);
      // @media only screen and (max-width: 768px) and (min-width: 430px) {
      //   height: calc(100vh - 59vh);
      // }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    padding: 0 32px;
    background: linear-gradient(180deg, #2b2b2b 0.69%, #131313 100%);
    .tick_logo {
      display: flex;
      justify-content: center;
      padding: 8px 0;
      .image {
        height: 40px;
        width: 40px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    &__heading {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      padding-bottom: 20px;
      color: #ffffff;
    }
    &__subs {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      padding-bottom: 20px;
      color: $grey;
      b {
        color: #ffffff;
      }
    }
    &__channel_head {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 126.1%;
      color: $grey;
      padding-bottom: 15px;
    }
    .channel_carousel_wrapper {
      margin-bottom: 15px;
      .slick-list {
        height: 72px;
      }
      .slick-track {
        height: 72px;
        .slick-slide {
          width: 106px !important;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .image-box {
        height: 72px;
        width: 106px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .validity {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      color: #9e9e9e;
      padding-bottom: 12px;
    }
    .transaction {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 126.1%;
      color: #9e9e9e;
      padding-bottom: 20px;
    }
    button {
      display: flex;
      justify-content: center;
      border-radius: 8px;
      height: 48px;
      color: $grey;
      cursor: pointer;
      background-color: #d8272c;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      width: 100%;
      border: none;
      outline: none;
      align-items: center;
    }
    .btn-chevron {
      margin-left: 5px;
    }
  }
}
