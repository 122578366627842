@import '../../styles/index.scss';

// .btn {
//   display: flex;
//   justify-content: center;
//   border: 1px solid $theme-red;
//   border-radius: 4px;
//   height: 32px;
//   cursor: pointer;
//   &.active {
//     background-color: $theme-red;
//   }
//   &.deactive {
//     background-color: $light-grey-1;
//     border: 1px solid $light-grey-1;
//     cursor: not-allowed;
//     pointer-events: none;
//   }
//   .txt {
//     color: $theme-red;
//     font-weight: 500;
//     font-size: $font-small;
//     align-items: center;
//     display: flex;
//     text-transform: uppercase;
//     &.light {
//       color: $white;
//     }
//     &.hide {
//       display: none;
//     }
//     &.deactive {
//       color: $light-grey-2;
//     }
//   }
//   &.flex-row-reverse {
//     flex-direction: row-reverse;
//   }
// }

.add-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23.5px;
  .add-btn {
    width: 24px;
    height: 24px;
    background: $grey-0;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
  label {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-self: center;
    padding: 2px 8px;
    font-family: $font-family-medium;
  }
}

.add-btn-wrapper-upi {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  .add-btn {
    width: 16px;
    height: 16px;
    background: $grey-0;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 8px;
      height: 8px;
    }
  }
  label {
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    display: flex;
    align-self: center;
    padding: 2px 8px;
    font-family: $font-family-medium;
  }
}

.button {
  .btn {
    height: 56px;
    .txt {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .add-btn-wrapper {
    .add-btn {
      width: 16px;
      height: 16px;
      img {
        width: 8px;
        height: 8px;
      }
    }
    label {
      font-size: 12px;
    }
  }

  .add-btn-wrapper-upi {
    label {
      font-size: 12px;
    }
  }
  .button {
    .btn {
      height: 44px;
      .txt {
        font-size: 12px;
      }
    }
  }
}
