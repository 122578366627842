@import './index.scss';
.scroll-class {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: $light-grey-2;
    border-radius: 4px;
  }
}
